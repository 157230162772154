import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroMini from "../components/heroSmall"

const PrivacyStatement = () => (
  <Layout>
    <Seo title="Privacy Statement" />
    <HeroMini title="Privacy Statement"/>
    <main className="section  has-text-black">
        <div className="container">
        <div className="columns">
            <div className="column content has-text-black">
                <p className="is-size-6">Fusion Development Limited (FDL) take your privacy seriously 
                    and are committed to protecting your right to privacy as a user of our website and our 
                    professional services. We have made every effort to ensure your information is 
                    accurate, secure and handled in accordance with the regulations and standards set 
                    out in the Data Protection Jersey (2018) Law.S This policy governs the manner in 
                    which we collect, use, maintain, retain and disclose information; so that as a 
                    “data subject”, you are able to use this information to make decisions about 
                    your privacy.</p><br/>
                <p className="is-size-3">Why we collect your personal data</p>
                <p className="is-size-6 mt-3">FDL is the “Data Controller” of your personal information. We endeavour to 
                    structure our core business activities in a clear and transparent manner, 
                    reflecting the different ways we may interact with you. Much of the information 
                    we hold comes directly from you, this includes:</p>
                <ul className="has-text-black ml-6 mt-4 is-size-6">                
                    <li>staff and employee information</li>
                    <li>suppliers, for contracts, services, billing business partner contracts</li>
                    <li>query box on our website</li>
                    <li>supplier contract</li>
                    <li>subject access request verifications</li>
                </ul>
                <p className="is-size-3 mt-6">What we do with your information</p>
                <p className="is-size-6 mt-3">FDL will not share your information unless it is essential to provide you with a 
                    service. We may provide information to our employees, business partners and 
                    carefully selected third party providers in order to fulfil our services to you 
                    or to meet our legal obligations. We may also share your information in the 
                    following ways:</p>
                <ul id="Body_content" className="has-text-black ml-6 mt-4 is-size-6">
                    <li>when we have your permission to share the information;</li>
                    <li>when you have been given prior notice, your information may be shared; and</li>
                    <li>when we are required by law or local government to disclose your information</li>
                </ul>

                <p className="is-size-3 mt-6">Retention periods</p>
                <p className="is-size-6 mt-3">FDL retains information for only as long as necessary to fulfil the purpose for 
                    which it was collected. We will not retain personal information longer than 
                    necessary to ensure compliance with legal and regulatory obligations, statutory 
                    retention terms, handling disputes, and for the establishment, exercise or 
                    defence of legal claims.
                </p>
                <p className="is-size-3 mt-6">Third party websites</p>
                <p className="is-size-6 mt-3">Users may find plug-in’s or other content on our site that links to the sites and 
                    services of our business partners, suppliers and other third parties. We do not 
                    control the content or links that appear on these sites and are not responsible for 
                    the practices employed by websites linked to or from our site. Browsing and 
                    interaction on any other website, including websites which have a link to our Site, 
                    is subject to that website's own terms and policies.
                </p>
                <p className="is-size-3 mt-6">Cookies</p>
                <p className="is-size-6 mt-3">FDL uses “Google Analytics” cookies on our website for tracking purposes.</p>
                <p className="is-size-3 mt-6">Data transfers</p>
                <p className="is-size-6 mt-3">FDL operates predominantly in Jersey in the Channel Islands and our data 
                    substantially resides in Jersey. We do use technology that may transfer data to 
                    another jurisdiction, for example use of Microsoft and cloud-based back-up of 
                    data. We will always ensure that your data is appropriately protected where such 
                    transfers do not offer the same level of protection of personal data.
                </p>
                <p className="is-size-3 mt-6">Information security</p>
                <p className="is-size-6 mt-3">FDL commits to protecting your personal information and maintaining its accuracy. We 
                    adopt appropriate physical &amp; technical safeguards, compliant processing practices and 
                    security measures to help us protect your personal information from unauthorised access, 
                    use and disclosure. We also require that our business partners and suppliers protect such 
                    information to the same standards
                </p>
                <p className="is-size-3 mt-6">Changes to this privacy policy</p>
                <p className="is-size-6 mt-3">FDL has the discretion to update this privacy policy at any time. When we do, we will 
                    revise the updated date at the bottom of this page. We encourage Users to frequently 
                    check this page for any changes to stay informed about how we are helping to protect 
                    the personal information we collect.
                </p>
                <p className="is-size-3 mt-6">Your rights</p>
                <p className="is-size-6 mt-3">If you have any questions or queries relating to our privacy policy, how we process your 
                    information, having your information modified/deleted or to gain access to the information 
                    held about you; please contact us in writing as follows:
                </p>
                <p className="is-size-6 mt-5">The Directors, Fusion Development Limited 
                <a href="mailto:dataprotection@fusion.je" className="privacylinkstyle">(dataprotection@fusion.je)</a>
                </p>
                <p className="is-size-6 mt-5">You also have the right to make a complaint directly to the Data Protection
                    Authority if you are unhappy with how we have handled your information; 
                    please contact them via their website <a href="https://jerseyoic.org/" className="privacylinkstyle">(https://jerseyoic.org/)</a>
                </p>
                <p className="is-size-6 mt-5 has-text-centered mb-6">Fusion Development Limited is registered with the Jersey Data Protection Authority, 
                    registration number Privacy Policy November 2020
                </p>
            </div>
        </div>
    </div>
    </main>
  </Layout>
)

export default PrivacyStatement;
